import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pgloading2',
  templateUrl: './pgloading2.component.html',
  styleUrls: ['./pgloading2.component.scss']
})
export class Pgloading2Component implements OnInit {

  @Input() showLoading = false

  constructor() { }

  ngOnInit() {
  }

}
