import { Component, OnInit, Renderer2, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class CanvasSpinnerComponent implements OnInit, AfterViewInit {

  canvas: any;
  constructor(private renderer2: Renderer2) { }
  // @Input() wrapper

  @ViewChild("wrapper") wrapper: ElementRef;

  ngOnInit() {
    this.canvas = this.renderer2.createElement('canvas');
  }

  ngAfterViewInit() {

    this.renderer2.appendChild (this.wrapper.nativeElement, this.canvas);

    const d = this.canvas.width = this.canvas.height = 120;
    const ctx = this.canvas.getContext('2d');

    ctx.translate(d/2, d/2);
    ctx.rotate(Math.PI * 360/360);
    ctx.lineWidth = Math.ceil(d / 50);
    ctx.lineCap = 'square';

    let opacity;
    for (let i = 0; i<=360; i++) {
        ctx.save();

        ctx.rotate((Math.PI * i/180));
        //ctx.translate(-ctx.lineWidth/2, ctx.lineWidth/2);

        ctx.beginPath();
        ctx.moveTo(0, 0);
        opacity = (360 - (i * 0.95)) / 360;
        ctx.strokeStyle = 'rgba(255,255,255,' + opacity.toFixed(2) +')';
        ctx.lineTo(0, d + 30);
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
    }

    ctx.globalCompositeOperation = 'source-out';
    ctx.beginPath();
    ctx.arc(0, 0, d/2, 2 * Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();

    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(0, 0, (d/2) * .9, 2 * Math.PI, false);
    ctx.fill();
  }

}
