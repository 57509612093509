import { Injectable } from '@angular/core';
import { ControlService, ControlStatus, Song } from 'cdg-karaoke-lib';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  prop: any =  {};
  currentSong: Song = { id: 0, title: '', artist: null , cdgUrl: null, albumArt: null }

  constructor(private ctrl: ControlService) { }

  test() {
    return 'hello from global service'
  }

  testSet(val: any) {
   this.prop.val = val;
  }

  testGet() {
    return this.prop;
  }

  isAudioPlaying() {
    return (this.ctrl.getStatus() === ControlStatus.PLAYING)?true: false;
  }

  getCurrentSong() {
    return this.currentSong;
  }

  setCurrentSong(song: Song) {
    this.currentSong = song;
  }
}
