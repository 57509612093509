/**
 *
 *  !! Coerces oObject to boolean
 *  (!true = false, !! true = true, !false = true, !!false = false)
 *
 *  (if value is null then it will return false)
 *  !! null = false
 *
 *  (if value is undefined then it will return false)
 *  !! undefined = false
 *
 *  (if value is empty string then it will return false)
 *  !! '' = false # empty string is false
 *
 *  !!parseInt("foo") === false // NaN is falsy
 *
 *   const firstTouch = getState(this.store)['appState'].ui.firstTouch;
 *   console.log('%c ** getState.ui.firstTouch **  ', 'color:red;background:yellow', firstTouch);
 *
 *   if (!!firstTouch === false) {
 *      console.log('%c ** firstTouch false **  ', 'color:white;background:red', firstTouch);
 *   }
 *   else {
 *      this.firstTouch = false;
 *      this.paused = true;
 *   }
 *
 *
 */
import { Action, State, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

export interface AppAction extends Action {
  which: string;
  type: string;
  payload?: any;
}

// which
export const UI = 'UI';
export const CDG = 'CDG';
export const AUDIO = 'AUDIO';

// action.type ui
export const FIRSTTOUCH = 'TIMEUPDATE';

// action.type audio
export const TIMEUPDATE = 'TIMEUPDATE';
export const LOADEDMETADATA = 'LOADEDMETADATA';
export const SONG = 'SONG';
// export const CANPLAY = 'CANPLAY';
// export const PLAYING = 'PLAYING';
// export const LOADSTART = 'LOADSTART';
// export const RESET = 'RESET';
// export const PAUSED = 'PAUSED';
// export const STOPPED = 'STOPPED';
// export const READY = 'READY';
// export const LOADED = 'LOADED';
// export const DECODED = 'DECODED';

// action.type cdg
export const CDGLOADED = 'CDGLOADED';
export const CDGINIT = 'CDGINIT';


// e.g. this.store.dispatch({ which: CDG, type: PLAYING, payload: { value: false } });
export function appStateReducer(state: any, action: AppAction) {

  const which = action.which;

  if (which === UI) { return uiReducer(state, action); }
  if (which === CDG) { return cdgReducer(state, action); }
  if (which === AUDIO) { return audioReducer(state, action); }

  state = {};
  state.audio = {};
  state.cdg = {};
  state.ui = {};
  return state;

}

export function getState(store: Store<any>): State<any> {
  let state: State<any>;

  // store.subscribe(s => state = s).unsubscribe();

  // take(1) unsubscribes automatically
  // https://stackoverflow.com/questions/40563065/difference-between-unsubscribe-to-take1
  store.pipe(take(1)).subscribe(s => state = s);

  return state;
}

/**
 * example
 *  by getState() returns obj
 *  obj = {appState: {audio:{}, cdg:{}, ui: {firstTouch: true}, which: "UI"}}
 *
 *   var s = getKeyByValue(obj['appState'], 'UI')
 *   s = 'which'
 *
 *   var s = getKeyByValue(obj['appState'].ui, true)
 *   s = 'firstTouch'
 *
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function uiReducer(state: any, action: AppAction) {

  const payload = action.payload;
  state = Object.assign({}, state);  // mebbe the same as ...state

  state.which = UI;
  switch (action.type) {
    case FIRSTTOUCH:
      state.ui.firstTouch = payload.value;
      return state;

    default:
      state = {};
      state.audio = {};
      state.cdg = {};
      state.ui = {};
      return state;
  }
}

function audioReducer(state: any, action: AppAction) {

  const payload = action.payload;
  state = Object.assign({}, state);

  state.which = AUDIO;
  switch (action.type) {

    case LOADEDMETADATA:
      state.audio.loadedmetadata = payload.value;
      state.audio.duration = payload.data.time;
      state.audio.durationSec = payload.data.timeSec;
      state.audio.mediaType = payload.data.mediaType;
      return state;

    case TIMEUPDATE:
      state.audio.time = payload.time;
      state.audio.timeSec = payload.timeSec;
      return state;

    case SONG:
      state.audio.song = payload.value;
      return state;

    default:
      state = {};
      state.audio = {};
      state.cdg = {};
      state.ui = {};
      return state;
  }

}

function cdgReducer(state: any, action: AppAction) {

  const payload = action.payload;
  state = Object.assign({}, state);

  state.which = CDG;
  switch (action.type) {
    case CDGLOADED:
      state.cdg.cdgloaded = payload.value;
      return state;

    case CDGINIT:
      state.cdg.cdginit = payload.value;
      return state;

    default:
      state = {};
      state.audio = {};
      state.cdg = {};
      state.ui = {};
      return state;
  }
}

// refs: https://www.learnrxjs.io/operators/
// https://www.intertech.com/Blog/ngrx-tutorial-accessing-state-in-the-store/
// https://stackoverflow.com/questions/40563065/difference-between-unsubscribe-to-take1
// https://stackoverflow.com/questions/25503627/how-to-get-the-value-using-key-from-json-in-javascript-jquery
