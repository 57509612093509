import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, Event } from '@angular/router';

import { NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { NavigationError, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Karaoke',
      url: '/karaoke',
      icon: 'microphone'
    },
    {
      title: 'Sandbox',
      url: '/sandbox',
      icon: 'logo-xbox'
    },
    // {
    //   title: 'List',
    //   url: '/list',
    //   icon: 'list'
    // },
  ];

  loading = true
  constructor(
    private _router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();

    this._router.events.subscribe( (routerEvent: Event) => {

      if (routerEvent instanceof NavigationStart) {
        this.loading = true;
      }
      if (routerEvent instanceof NavigationEnd    ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError) {
        this.loading = false;
      }

    })

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
