import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WavestouchDirective } from './directive/wavestouch.directive';
import { CanvasSpinnerComponent } from './components/canvas/spinner/spinner.component';
import { Pgloading1Component } from './components/pgloading/pgloading1/pgloading1.component';
import { Pgloading2Component } from './components/pgloading/pgloading2/pgloading2.component';
import { Pgloading3Component } from './components/pgloading/pgloading3/pgloading3.component';
import { Pgloading4Component } from './components/pgloading/pgloading4/pgloading4.component';

@NgModule({
  declarations: [
    WavestouchDirective, CanvasSpinnerComponent,
    Pgloading1Component, Pgloading2Component, Pgloading3Component, Pgloading4Component
  ],
  imports: [
    CommonModule
  ],
  // to use components outside of this module
  exports: [
    WavestouchDirective,
    CanvasSpinnerComponent,
    Pgloading1Component, Pgloading2Component, Pgloading3Component, Pgloading4Component
  ]
})
export class GlobalModule { }
