import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

// my global service
import { GlobalService } from './shared/global.service';

// NgRx
import { StoreModule} from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { appStateReducer } from './shared/store/app.state';

// OVERRIDE HAMMERJS to enable (swipeup) and (swipedown)
// Need to install typings
// $ npm install --save @types/hammerjs
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GlobalModule } from './shared/global/global.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_ALL  }
  }
  // // also like this
  // overrides = <any>{
  //   'swipe': {velocity: 0.4, threshold: 20} // override default settings
  // }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,

    // Start (cip)
    HttpClientModule,
    GlobalModule,

    // name of store: reducer function
    // keep one store for simplicity
    StoreModule.forRoot({ appState: appStateReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

  ],
  providers: [
    // My GLOBAL SERVICE available in all module
    // i.e. import { } from ...
    GlobalService,

    //
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    // OVERRIDE HAMMERJS to enable (swipeup) and (swipedown)
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
