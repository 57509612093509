import { Directive, HostListener, HostBinding, Renderer2 } from '@angular/core';

// NEEDS ANIME.JS
import * as anime from 'animejs'

@Directive({
  // selector: '[appWavestouch]'
  selector: '[cpWavestouch]',
  // tslint complains, use host binding instead
  // host: {
  //   '[style.background-color]': '"yellow"'
  // }
})
export class WavestouchDirective {

  // // test only - works!
  // @HostBinding('style.background-color')
  // backgroundColor = 'yellow';

  @HostBinding('style.position') position = 'relative';
  @HostBinding('style.overflow') overflow = 'hidden';

  constructor(private renderer2: Renderer2) { }

  // BE CAREFUL WITH TARGET!
  // DIV can have INNER COMPONENTS so target will be set to that!
  @HostListener('click', ['$event'])
  onclick(event) {
    // console.log('%c HostListener event  ', 'color:white;background:#41b883', event);

    const target = event.target || event.srcElement || event.currentTarget;

    // console.log('%c store target  ', 'color:white;background:#41b883', target);
    // console.log('-- target.clientHeight ', target.clientHeight);

    // console.log('el.offetLeft', target.offsetLeft)
    // console.log('el.offetTop', target.offsetTop)
    // console.log('pageX', event.pageX)
    // console.log('pageY', event.pageY)

    // // could use offsetX and offsetY but it appears to have inconsistent support
    // // offsetX and offsetY is the X,Y locatiion relative to target pos of 0,0
    // // since it is defined as 'relative'
    // // see also ref: http://www.jacklmoore.com/notes/mouse-position/

    const magic = 42; // todo:

    // // const yPos = event.pageY - target.offsetTop;
    // // find out why we need magic, using trial and error for
    // // the grid layout only in sandbox. Could be different in other
    // // layouts. so far 42 is good bt not perfect.

    const xPos = event.pageX - target.offsetLeft;
    const yPos = event.pageY - target.offsetTop - magic;

    // console.log('xPos pageX-targe.offsetLeft', xPos)
    // console.log('yPos pageY-targe.offsetTop',  yPos)

    // console.log('offsetX', event.offsetX)
    // console.log('offsetY', event.offsetY)

    // // yea available
    // console.log('%c ** renderer2 in HostListener **  ', 'color:white;background:red', this.renderer2);

    const elWavesRipple = this.renderer2.createElement('div');

    // // .waves-ripple {
    // //   background-color: rgba(255, 255, 255, 0.4);
    // //   border-radius: 100%;
    // //   width: 10px;
    // //   height: 10px;
    // //   position: absolute;
    // // }

    this.renderer2.addClass(elWavesRipple, "waves-ripple")
    this.renderer2.setStyle(elWavesRipple, 'background-color', 'rgba(255,255,255,0.4')
    this.renderer2.setStyle(elWavesRipple, 'border-radius', '100%')
    this.renderer2.setStyle(elWavesRipple, 'width', '10px')
    this.renderer2.setStyle(elWavesRipple, 'height', '10px')

    // !IMPORTANT! absolute relative to targets (relative)
    this.renderer2.setStyle(elWavesRipple, 'position', 'absolute')

    this.renderer2.setStyle(elWavesRipple, 'left', xPos + 'px')
    this.renderer2.setStyle(elWavesRipple, 'top',  yPos + 'px')

    const rippleElm = this.renderer2.appendChild (target, elWavesRipple);
    // console.log('%c rippleElm', 'color:white;background:#41b883', rippleElm); // undefined

      anime({
        targets: '.waves-ripple',
        scale: {
          value: 40,
          // value: -2,   // for debug
          duration: 1000,
        },
        // comment to debug
        opacity: {
          value: 0,
          duration: 1000
        },
        easing: 'easeOutSine',
        // // javascript way
        // ------------------
        // complete: function() {
        //   const newElm = document.getElementsByClassName('waves-ripple')[0]
        //   newElm.remove();
        // }
        // // typescript way
        // ------------------
        complete: () => {
          console.log('@@ complete @@')
          // comment to debug
          this.renderer2.removeChild(target, elWavesRipple)
        }
      }); // end anime

  } // end onClick
} // end directive
