import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pgloading4',
  templateUrl: './pgloading4.component.html',
  styleUrls: ['./pgloading4.component.scss']
})
export class Pgloading4Component implements OnInit {

  @Input() showLoading = false

  constructor() { }

  ngOnInit() {
  }

}
