import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'karaoke',
    pathMatch: 'full'
  },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'karaoke', loadChildren: './karaoke/karaoke.module#KaraokePageModule' },
  { path: 'list', loadChildren: './list/list.module#ListPageModule' },
  { path: 'sandbox', loadChildren: './sandbox/sandbox.module#SandboxPageModule' },
  { path: 'navigation', loadChildren: './sandbox/navigation/navigation.module#NavigationPageModule' },
  { path: 'yoda', loadChildren: './yoda/yoda.module#YodaPageModule' },
  { path: 'ionic', loadChildren: './sandbox/ionic/ionic.module#IonicPageModule' },
  //
  { path: 'fullcover', loadChildren: './fullcover/fullcover.module#FullcoverPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
